import React, { FC } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../svg/logo.svg";

import "./header.scss";

const Header: FC = () => {
  return (
    <header className="header">
      <div className="header__content container">
        <div className="header__logo">
          <Logo />
          <strong>VIP Transfer KMV</strong>
        </div>
        <nav className="header__nav">
          <Link to="/">Главная</Link>
          <Link to="/order">Маршрут</Link>
          <Link to="/van">Минивены</Link>
          <Link to="/premium">Премиум</Link>
          <Link to="/business">Бизнес</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;