import React, { FC } from "react";
import { Link } from "react-router-dom";

import Split, { SplitBlock } from "../../components/common/Split/Split";
import Carousel from "../../components/common/Carousel/Carousel";

import { carAbout } from "../../lib/types/carAbout";

import "./carabout.scss";

interface carAboutProps {
  data: carAbout
}

const CarAbout: FC<carAboutProps> = ({ data }) => {
  return (
    <Split>
      <SplitBlock type="grow">
        <Carousel height="400px" data={data.images} />
      </SplitBlock>
      <SplitBlock type="fixed" width="400px">
        <div className="block">
          <div className="car-about">
            <strong>{data.name}</strong>
            <span>{data.description}</span>
            <div className="car-about__content">
              {
                data.specs.map((item, index) => (
                <div key={index} className="car-about__spec">
                  {item.icon}
                  <span>{item.value}</span>
                </div>
                ))
              }
            </div>
            <Link to="/order" className="car-about__btn style--accent">Составить маршрут</Link>
          </div>
        </div>
      </SplitBlock>
    </Split>
  );
};

export default CarAbout;