import React, { FC, useState } from "react";

import "./carswitch.scss";

interface carButtonsProps {
  data: {
    name: string,
    image: string
  }[]
  onClick?: (item: any, index: number) => void
}

const CarSwitch: FC<carButtonsProps> = ({ data, onClick }) => {
  const [select, setSelect] = useState(0);

  return (
    <div className="cars-switch">
      {
        data.map((item, index) => 
        <button key={index} onClick={() => {
          setSelect(index);
          onClick && onClick(item, index);
        }} className={select === index ? "cars-switch__item cars-switch--select" : "cars-switch__item"}>
          <img src={item.image} alt="" />
          <strong>{item.name}</strong>
        </button>
        )
      }
    </div>
  );
};

export default CarSwitch;