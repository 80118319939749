export const citiesGeoposition: {[key: string]: [number, number]} = {
  "минеральные воды": [
    44.226135939458075, 
    43.082931499999916
  ],
  "аэропорт мин-воды": [
    44.226135939458075, 
    43.082931499999916
  ],
  "пятигорск": [
    44.035629349306525, 
    43.06481121179198
  ],
  "домбай": [
    43.29447335441571, 
    41.621704999999956
  ],
  "кисловодск": [
    43.90196501559826, 
    42.74423999999996
  ],
  "ессентуки": [
    44.037676626440366, 
    42.87786399999995
  ],
  "краснодар": [
    45.172112768157305, 
    39.132522999999985
  ],
  "эльбрус": [
    43.342236485997745, 
    42.47929049999996
  ],
  "азау": [
    43.26648806118469, 
    42.48009049999994
  ],
  "чегет": [
    43.24151400790716, 
    42.51077649999998
  ]
}