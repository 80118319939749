import React, { FC, useState } from "react";

import InputList from "../common/InputList/InputList";

import { answers as data } from "../../lib/data/answers";

import "./answers.scss";

const Answers: FC = () => {
  const inputData = data.map(item => item.value);

  const [target, setTarget] = useState<any>(data[0]);

  return (
    <div className="answers">
      <InputList defaultValue={target?.value} pickOnly={true} clearValue={false} className="answers__list" 
        data={inputData} 
        onChange={(value: string) => {
          setTarget(data.find(item => item.value === value));
        }}
      />
      <span>{target?.text}</span>
    </div>
  );
};

export default Answers;