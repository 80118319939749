import { carAbout } from "../../types/carAbout";

import { ReactComponent as Circle } from "../../../svg/circle.svg"; 

export const vanCars: carAbout[] = [
  {
    name: "Mercedes-Benz V-class 447 long 2022",
    description: "Cтильный и универсальный минивэн, созданный для обеспечения исключительного комфорта и функциональности как для семьи, так и для бизнеса",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Burmester"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      },
      {
        icon: <Circle/>,
        value: "Просторный салон"
      }
    ],
    images: [
      "/common/cars/van/one/1.png",
      "/common/cars/van/one/2.png",
      "/common/cars/van/one/3.png",
      "/common/cars/van/one/4.png",
      "/common/cars/van/one/5.png",
      "/common/cars/van/one/6.png",
      "/common/cars/van/one/7.png",
      "/common/cars/van/one/8.png",
      "/common/cars/van/one/9.png",
    ]
  },
  {
    name: "Mercedes-Benz V-class 447 long 2020",
    description: "Cтильный и универсальный минивэн, созданный для обеспечения исключительного комфорта и функциональности как для семьи, так и для бизнеса",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Burmester"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      },
      {
        icon: <Circle/>,
        value: "Просторный салон"
      }
    ],
    images: [
      "/common/cars/van/one/1.png",
      "/common/cars/van/one/2.png",
      "/common/cars/van/one/3.png",
      "/common/cars/van/one/4.png",
      "/common/cars/van/one/5.png",
      "/common/cars/van/one/6.png",
      "/common/cars/van/one/7.png",
      "/common/cars/van/one/8.png",
      "/common/cars/van/one/9.png",
    ]
  },
  {
    name: "Mercedes-Benz V-class 447 extra long",
    description: "Cтильный и универсальный минивэн, созданный для обеспечения исключительного комфорта и функциональности как для семьи, так и для бизнеса",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Burmester"
      },
      {
        icon: <Circle/>,
        value: "Зеркальный потолок"
      },
      {
        icon: <Circle/>,
        value: "Просторный салон"
      }
    ],
    images: [
      "/common/cars/van/three/1.png",
      "/common/cars/van/three/2.png",
      "/common/cars/van/three/3.png",
      "/common/cars/van/three/4.png",
    ]
  },
  {
    name: "HYUNDAI STARIA 2022",
    description: "Cовременный минивэн, созданный для обеспечения комфорта, универсальности и стиля",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Bose"
      },
      {
        icon: <Circle/>,
        value: "Панорамная крыша"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      }
    ],
    images: [
      "/common/cars/van/two/1.png",
      "/common/cars/van/two/2.png",
      "/common/cars/van/two/3.png",
      "/common/cars/van/two/4.png",
      "/common/cars/van/two/5.png",
      "/common/cars/van/two/6.png",
    ]
  },
]