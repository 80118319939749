import React, { FC, useReducer } from "react";

import "./cartable.scss";

interface carTableProps {
  data: any[],
  onClick?: (status: boolean, car: Record<string, any>) => void
}

function reducer(state: string[], action: string) {
  if (state.includes(action)) {
    return state.filter(item => item !== action);
  } else {
    return [...state, action];
  }
}

const CarTable: FC<carTableProps> = ({ data, onClick }) => {

  const [state, dispatch] = useReducer(reducer, []);

  function toggle(item: any) {
    onClick && onClick(!state.includes(item.brand + " " + item.name), item);
    dispatch(item.brand + " " + item.name);
  }

  return (
  <table className="cars-table">
    <tbody>
      {
        data.map((item, index) => 
          <tr key={index}>
            <td>
              <img src={item.image} alt="" />
            </td>
            <td>
              <strong>{item.brand}</strong>
            </td>
            <td>
              <span>{item.name}</span>
            </td>
            <td>
              {
                state.includes(item.brand + " " + item.name)
                ? <button onClick={() => toggle(item)} className="style--translucent-15-negative">Убрать</button>
                : <button onClick={() => toggle(item)} className="style--accent">Добавить</button>
              }
            </td>
          </tr>
        )
      }
    </tbody>
  </table>
  );
};

export default CarTable;