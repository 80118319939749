import React, { FC } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Car } from "../../svg/car.svg";
import { ReactComponent as Person } from "../../svg/person.svg";

import "./carcards.scss";

interface carsCardsProps {
  data: {
    title: string,
    subtitle: string,
    img: string,
    cars: number,
    persons: string,
    link: string
  }[]
}

const CarCards: FC<carsCardsProps> = ({ data }) => {
  return (
    <div className="car-cards">
      {
        data.map((item, index) =>
        <Link to={item.link} key={index} className="car-cards__item">
          <img src={item.img} alt="" />
          <div className="car-cards__item-header">
            <strong>{item.title}</strong>
            <div className="car-cards__item-stats">
              <div className="car-cards__item-stat">
                <Person />
                <span>{item.persons}</span>
              </div>
              <div className="car-cards__item-stat">
                <Car />
                <span>{item.cars}</span>
              </div>
            </div>
          </div>
          <div className="car-cards__item-content">
            <span>{item.subtitle}</span>
          </div>
        </Link>
        )
      }
    </div>
  );
};

export default CarCards;