import React, { FC } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"

import { publicPaths } from "./paths";

const AppRouter: FC = () => {
  const router = createBrowserRouter([
    {
      errorElement: <Navigate to="/"></Navigate>
    },
    ...publicPaths
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;