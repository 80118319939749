import { RouteObject } from "react-router-dom";

import IndexLayout from "../layouts/IndexLayout/IndexLayout";

import IndexPage from "../pages/IndexPage/IndexPage";
import OrderPage from "../pages/OrderPage/OrderPage";
import VanPage from "../pages/VanPage/VanPage";
import PremiumPage from "../pages/PremiumPage/PremiumPage";
import BusinessPage from "../pages/BusinessPage/BusinessPage";

export const publicPaths: RouteObject[] = [
  {
    path: "/",
    element: <IndexLayout />, 
    children: [
      {
        path: "/",
        element: <IndexPage />
      },
      {
        path: "/order",
        element: <OrderPage />
      },
      {
        path: "/van",
        element: <VanPage />
      },
      {
        path: "/premium",
        element: <PremiumPage />
      },
      {
        path: "/business",
        element: <BusinessPage />
      }
    ]
  }
];