import { carAbout } from "../../types/carAbout";

import { ReactComponent as Circle } from "../../../svg/circle.svg"; 

export const businessCars: carAbout[] = [
  {
    name: "Mercedes-Benz E 200",
    description: "Роскошный седан, сочетающий в себе элегантность, производительность и передовые технологии.",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Burmester"
      },
      {
        icon: <Circle/>,
        value: "Панорамная крыша"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      }
    ],
    images: [
      "/common/cars/business/four/1.png",
      "/common/cars/business/four/2.png"
    ]
  },
  {
    name: "BMW 5 Series",
    description: "Сочетание в себе стиля, комфорта и динамичности, что делает его популярным выбором для тех, кто ищет изысканный и приятный седан класса люкс.",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Bowers & Wilkins"
      },
      {
        icon: <Circle/>,
        value: "Хорошая динамика"
      },
      {
        icon: <Circle/>,
        value: "Люк"
      }
    ],
    images: [
      "/common/cars/business/three/1.png",
      "/common/cars/business/three/2.png",
      "/common/cars/business/three/3.png",
      "/common/cars/business/three/4.png",
      "/common/cars/business/three/5.png",
      "/common/cars/business/three/6.png"
    ]
  },
  {
    name: "TOYOTA Camry 70",
    description: "Надежный и популярный седан среднего размера, известный своей исключительной топливной экономичностью, комфортом и практичностью",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика JBL"
      },
      {
        icon: <Circle/>,
        value: "Просторный седан"
      },
      {
        icon: <Circle/>,
        value: "4х-зонный климат контроль"
      },
    ],
    images: [
      "/common/cars/business/one/1.png",
      "/common/cars/business/one/2.png",
      "/common/cars/business/one/3.png",
      "/common/cars/business/one/4.png",
      "/common/cars/business/one/5.png",
      "/common/cars/business/one/6.png",
      "/common/cars/business/one/7.png",
      "/common/cars/business/one/8.png"
    ]
  },
  {
    name: "KIA K5",
    description: "Cтильный и современный седан среднего размера, сочетающий в себе производительность, комфорт и расширенные функции",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Bose"
      },
      {
        icon: <Circle/>,
        value: "Панорамная крыша"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      },
    ],
    images: [
      "/common/cars/business/two/1.png",
      "/common/cars/business/two/2.png",
      "/common/cars/business/two/3.png",
      "/common/cars/business/two/4.png",
      "/common/cars/business/two/5.png",
      "/common/cars/business/two/6.png"
    ]
  }
]