import React, { FC, useEffect, useReducer, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Store } from 'react-notifications-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import OrderService from "../../api/OrderService";

import { usePage } from "../../hooks/usePage";
import { useFetching } from "../../hooks/useFetching";

import Split, { SplitBlock } from "../../components/common/Split/Split";
import InputList from "../../components/common/InputList/InputList";
import CarTable from "../../components/CarTable/CarTable";
import CarSwitch from "../../components/CarSwitch/CarSwitch";
import YMap from "../../components/common/YMap/YMap";

import { ReactComponent as Person } from "../../svg/person.svg";
import { ReactComponent as Car } from "../../svg/car.svg";
import { ReactComponent as Schedule } from "../../svg/schedule.svg";
import { ReactComponent as Distance } from "../../svg/distance.svg";
import { ReactComponent as Error } from "../../svg/error.svg";
import { ReactComponent as TelegramIcon } from "../../svg/social/telegram.svg";
import { ReactComponent as WhatsappIcon } from "../../svg/social/whatsapp.svg";

import { cities } from "../../lib/data/cities";
import { citiesGeoposition } from "../../lib/data/citiesGeoposition";
import { carsList } from "../../lib/data/carsList";
import { routesLenght } from "../../lib/data/routesLenght";

import "./orderpage.scss";

interface Iorder {
  from: string,
  to: string,
  persons: number,
  childrens: number,
  date: string,
  contact: {
    name: string,
    phone: string,
    additional: string
  },
  cars: {
    name: string,
    brand: string,
    image: string,
    seats: number
  }[],
  route: {
    distance: number,
    time: string
  }
}

function reducer(state: Iorder, action: Record<string, any>) {
  return {...state, ...action};
}

const OrderPage: FC = () => {
  usePage();

  const [targetCategory, setTargetCategory] = useState<string>("Минивены");
  const [mapPlacemarks, setMapPlacemarks] = useState<any[]>([]);

  const defaultState = {
    from: "",
    to: "",
    persons: 1,
    childrens: 0,
    date: new Date().toISOString().slice(0, 10),
    contact: {
      name: "",
      phone: "",
      additional: "",
    },
    cars: [],
    route: {
      distance: 0,
      time: "0 м.",
    }
  }

  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, defaultState);

  const [fetchOrder] = useFetching(async (order: any) => {
    const sendOrder = await OrderService.sendOrder(order)
    if (sendOrder.data.status) {
      Store.addNotification({
        title: "Ваша заявка отправлена!",
        message: "Мы свяжемся с вами в ближайшее время",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 10000,
        },
      });

      dispatch(defaultState);
      window.scrollTo(0, 0);
    } else {
      Store.addNotification({
        title: "Ошибка",
        message: "Ваша заявка была отклонена сервером",
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 10000,
        },
      });
    }
  })

  function sendOrder() {
    const validateErrors: {title: string, message: string}[] = [];
    if (state.from === "" || state.to === "") {
      validateErrors.push({
        title: "Ошибка",
        message: "Заполните поля `откуда` и `куда`",
      });
    }

    if (state.persons <= 0) {
      validateErrors.push({
        title: "Ошибка",
        message: "Укажите количество человек",
      });
    }

    if (state.contact.name === "" || state.contact.phone === "") {
      validateErrors.push({
        title: "Ошибка",
        message: "Укажите контактные данные", 
      });
    }

    if (state.cars.length === 0) {
      validateErrors.push({
        title: "Ошибка",
        message: "Вы не добавили автомобили", 
      });
    }

    if (state.cars.length !== 0 && state.persons > seats) {
      validateErrors.push({
        title: "Ошибка",
        message: "В выбранных автомобилях недостаточно мест", 
      });
    }

    if (validateErrors.length > 0) {
      validateErrors.forEach((item) => {
        Store.addNotification({
          title: item.title,
          message: item.message,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 10000,
          },
        });
      })
      return;
    }

    fetchOrder(state);
  }

  const seats = useMemo(() => {
    return state.cars.reduce((acc, item) => acc + item.seats, 0)
  }, [state.cars]);

  function toggleCar(status: boolean, car: Record<string, any>) {
    dispatch({cars: status ? [...state.cars, car] : state.cars.filter((item) => item.name !== car.name)})
  }

  useEffect(() => {
    dispatch({
      from: searchParams.get("from") || "",
      to: searchParams.get("to") || "",
      persons: Number(searchParams.get("persons")) || 1,
      date: searchParams.get("date") || new Date().toISOString().slice(0, 10),
    })
  }, [searchParams]);

  useEffect(() => {
    if (state.from === "" || state.to === "") {
      return;
    }

    for (const item in routesLenght) {
      if (item.includes(state.from.toLowerCase()) && item.includes(state.to.toLowerCase())) {
        dispatch({
          route: {
            distance: routesLenght[item].distance,
            time: routesLenght[item].time
          }
        });

        setMapPlacemarks([
          {
            caption: "Откуда",
            geo: citiesGeoposition[state.from.toLowerCase()]
          },
          {
            caption: "Куда",
            geo: citiesGeoposition[state.to.toLowerCase()]
          }
        ]);

        break;
      }
    }
  }, [state.from, state.to]);
  return (
    <section className="content">
      <Helmet>
        <title>Закажите минивены и машины премиум класса в Минеральных Водах и КМВ на любое расстояние vip-transfer-kmv.ru</title>
      </Helmet>
      <div className="block-container">
        <Split>
          <SplitBlock type="grow">
            <div className="block order-contact">
              <div className="alert style--accent-lite style--fill-accent">
                <span>Вы также можете заказать трансфер по телефону {process.env.REACT_APP_PRETTY_PHONE}</span>               
                <div className="order-contact__social">
                  <Link to={process.env.REACT_APP_TELEGRAM!}>
                    <TelegramIcon />
                  </Link>
                  <Link to={process.env.REACT_APP_WHATSAPP!}>
                    <WhatsappIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="order-form">
                <h4>Определимся с маршрутом</h4>
                <div className="order-form__row">
                  <div className="order-form__input">
                    <span>Откуда*</span>
                    <InputList defaultValue={state.from} className="order-form__input-list" clearValue={false} data={cities} onChange={(value) => dispatch({from: value})} />
                  </div>
                  <div className="order-form__input">
                    <span>Куда*</span>
                    <InputList defaultValue={state.to} className="order-form__input-list" clearValue={false} data={cities} onChange={(value) => dispatch({to: value})}/>
                  </div>
                </div>
                <div className="order-form__row">
                  <div className="order-form__input">
                    <span>Дата*</span>
                    <input type="date" value={state.date} min={state.date} onChange={(e) => dispatch({date: e.target.value})} />
                  </div>
                </div>
                <h4>Сколько Вас будет?</h4>
                <div className="order-form__row">
                  <div className="order-form__input">
                    <span>Кол-во человек*</span>
                    <input type="number" min={1} max={50} value={state.persons} onChange={(e) => dispatch({persons: e.target.value})} />
                  </div>
                  <div className="order-form__input">
                    <span>Из них дети (до 7 лет)*</span>
                    <input type="number" min={0} max={state.persons} value={state.childrens} onChange={(e) => dispatch({childrens: e.target.value})} />
                    <span style={{fontSize: "75%"}}>Укажите количество детей чтобы мы предоставили Вам детские кресла</span>
                  </div>
                </div>
                <h4>Контактная информация</h4>
                <div className="order-form__row">
                  <div className="order-form__input">
                    <span>ФИО*</span>
                    <input type="text" placeholder="Фамилия Имя Отчество" value={state.contact.name} onChange={(e) => dispatch({contact: {...state.contact, name: e.target.value}})} />
                  </div>
                </div>
                <div className="order-form__row">
                  <div className="order-form__input">
                    <span>Номер телефона*</span>
                    <input type="text" placeholder="+7 (___) ___-__-__" value={state.contact.phone} onChange={(e) => dispatch({contact: {...state.contact, phone: e.target.value}})} />
                  </div>
                  <div className="order-form__input">
                    <span>Почта или доп. телефон</span>
                    <input type="text" placeholder="example@mail.ru" value={state.contact.additional} onChange={(e) => dispatch({contact: {...state.contact, additional: e.target.value}})} />
                  </div>
                </div>
                <h4>Выберите автомобили</h4>
                <CarSwitch onClick={(item) => setTargetCategory(item.name)} data={[
                  {
                    image: "/common/car-van.png",
                    name: "Минивены",
                  },
                  {
                    image: "/common/car-premium.png",
                    name: "Премиум",
                  },
                  {
                    image: "/common/car-business.png",
                    name: "Бизнес",
                  }
                ]}/>
                <CarTable onClick={(status, car) => toggleCar(status, car)} data={carsList[targetCategory]}/>
                <div className="order-form__submit">
                  <span>Мы свяжемся с вами в течении 20 минут</span>
                  <button onClick={() => sendOrder()} className="style--accent">Отправить заявку</button>
                </div>
              </div>
            </div>
          </SplitBlock>
          {
            window.innerWidth > 900 &&
            <SplitBlock type="fixed" width="400px">
              <div className="order-summary block">
                <div className="stats">
                  <div className={`stats__item ${seats < state.persons ? "style--translucent-15-negative" : "style--translucent-15-positive"}`}>
                    <Person />
                    <strong>{state.persons} / {seats}</strong>
                  </div>
                  <div className="stats__item style--translucent-15-accent">
                    <Car />
                    <strong>{state.cars.length}</strong>
                  </div>
                  <div className="stats__item style--translucent-15-accent">
                    <Distance />
                    <strong>{state.route.distance} км</strong>
                  </div>
                  <div className="stats__item style--translucent-15-accent">
                    <Schedule />
                    <strong>{state.route.time}</strong>
                  </div>
                </div>
                {
                  seats < state.persons &&
                  <div className="alert style--negative-lite style--fill-negative">
                    <Error />
                    <span>Вы не посадили всех пассажиров</span>
                  </div>
                }
                <YMap  
                  style={{height: "250px"}}
                  state={{
                    center: citiesGeoposition["минеральные воды"],
                    zoom: 7
                  }}
                  placemarks={mapPlacemarks}
                />
              </div>
              {
                state.route.distance > 100 &&
                <div className="block">
                  <div className="alert style--warning-lite style--fill-warning">
                    <Error style={{zoom: 1.2}} />
                    <span>Вас ожидает длительная поездка, будьте к ней готовы</span>
                  </div>
                </div>
              }
              <div className="block">
                {
                  state.cars.length === 0
                  ? <div style={{padding: "30px 0px"}} className="alert">Автомобили не выбраны</div>
                  : <div className="car-list">
                      {
                        state.cars.map((item, index) => (
                          <div key={index} className="car-list__item">
                            <img src={item.image} alt="" />
                            <div className="car-list__title">
                              <strong>{item.brand}</strong>
                              <span>{item.name}</span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                }
              </div>
            </SplitBlock>
          }
        </Split>
      </div>
    </section>
  );
};

export default OrderPage;