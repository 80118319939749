export const carsList: any = {
  "Минивены": [
    {
      brand: "Mercedes-Benz",
      name: "V-class 447 Long 2022",
      image: "/common/cars/van/one/7.png",
      seats: 7
    },
    {
      brand: "Mercedes-Benz",
      name: "V-class 447 Long 2020",
      image: "/common/cars/van/one/7.png",
      seats: 7
    },
    {
      brand: "Mercedes-Benz",
      name: "V-class 447 Extra Long 2020",
      image: "/common/cars/van/three/3.png",
      seats: 7
    },
    {
      brand: "Hyundai",
      name: "Staria 2022",
      image: "/common/cars/van/two/4.png",
      seats: 7
    },
  ],
  "Премиум": [
    {
      brand: "Mercedes-Benz",
      name: "S-class W223",
      image: "/common/cars/premium/one/4.png",
      seats: 3
    },
    {
      brand: "Mercedes-Benz",
      name: "S-class W223 White",
      image: "/common/cars/premium/two/4.png",
      seats: 3
    },
    {
      brand: "Mercedes-Benz",
      name: "S-class W222",
      image: "/common/car-premium.png",
      seats: 3
    },
    {
      brand: "BMW",
      name: "7 Long",
      image: "/common/cars/premium/three/5.png",
      seats: 3
    },
  ],
  "Бизнес": [
    {
      brand: "Mercedes-Benz",
      name: "E 200",
      image: "/common/cars/business/four/3.png",
      seats: 3
    },
    {
      brand: "BMW",
      name: "5 Series",
      image: "/common/cars/business/three/4.png",
      seats: 3
    },
    {
      brand: "Toyota",
      name: "Camry 70",
      image: "/common/cars/business/one/6.png",
      seats: 3
    },
    {
      brand: "KIA",
      name: "К5",
      image: "/common/cars/business/two/5.png",
      seats: 3
    }
  ]
}