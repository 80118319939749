import React, { FC } from "react";

import { ReactComponent as Arrow } from "../../svg/arrow.svg";
import { ReactComponent as Airplane } from "../../svg/airplane.svg";

import "./carroutes.scss";

interface carRoutesProps {
  data: {
    from: {
      name: string,
      isAirport: boolean
    },
    to: {
      name: string,
      isAirport: boolean
    },
    time: string,
    distance: string
    onClick?: () => void
  }[]
}

const CarRoutes: FC<carRoutesProps> = ({ data }) => {
  return (
    <div className="car-routes">
      <h4>Популярные маршруты</h4>
      <div className="car-routes__content">
        {
          data.map((item, index) => (
            <button onClick={item.onClick} key={index} className="car-routes__item">
              <div className="car-routes__column">
                <div className="car-routes__header">
                  {
                    item.from.isAirport &&
                    <Airplane />
                  }
                  <strong>{item.from.name}</strong>
                  <Arrow />
                  {
                    item.to.isAirport &&
                    <Airplane />
                  }
                  <strong>{item.to.name}</strong>
                </div>
                <span>{item.time} - {item.distance}</span>
              </div>
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default CarRoutes;