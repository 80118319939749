import React, { FC, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import Split, { SplitBlock } from "./../../components/common/Split/Split";

import OrderPreview from "../OrderPreview/OrderPreview";
import CarRoutes from "../CarRoutes/CarRoutes";
import YMap from "../common/YMap/YMap";

import { carRoutes } from "../../lib/data/carRoutes";
import { citiesGeoposition } from "../../lib/data/citiesGeoposition";

import { ReactComponent as TelegramIcon } from "../../svg/social/telegram.svg";
import { ReactComponent as WhatsappIcon } from "../../svg/social/whatsapp.svg";

import "./preview.scss";

const Preview: FC = () => {
  const carRoutesData = useMemo(() => {
    return carRoutes.map((item) => {
      return {...item, onClick: () => {
        setPreviewData({from: item.from.name, to: item.to.name});
        setMapState({center: citiesGeoposition[item.from.name.toLowerCase()], zoom: 7});
        setRoutesPlacemarks([
          {
            caption: "Откуда",
            geo: citiesGeoposition[item.from.name.toLowerCase()]
          },
          {
            caption: "Куда",
            geo: citiesGeoposition[item.to.name.toLowerCase()]
          }
       ])
      }}
    })
  }, []);

  const [mapState, setMapState] = useState({
    center: citiesGeoposition["минеральные воды"],
    zoom: 10
  });

  const [previewData, setPreviewData] = useState({});

  const [routesPlacemarks, setRoutesPlacemarks] = useState([
    {
      caption: "Откуда",
      geo: citiesGeoposition["минеральные воды"]
    },
    {
      caption: "Куда",
      geo: citiesGeoposition["пятигорск"]
    },
  ]);

  return (
    <div className="preview">
      <div className="title">
        <h2>Закажите трансфер в Минеральных водах на любое расстояние</h2>
        <span>Ощутите незабываемые путешествия с Vip Transfer KMV!</span>
      </div>
      <OrderPreview data={previewData}/>
      <div className="preview__phone">
        <span>Позвоните для заказа или уточнения</span>
        <a href={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PRETTY_PHONE}</a>
        <div className="preview__social">
          <Link to={process.env.REACT_APP_TELEGRAM!}>
            <TelegramIcon />
          </Link>
          <Link to={process.env.REACT_APP_WHATSAPP!}>
            <WhatsappIcon />
          </Link>
        </div>
      </div>
      <Split className="preview__map">
        <SplitBlock type="grow">
          <YMap placemarks={routesPlacemarks} state={mapState}/>
        </SplitBlock>
        <SplitBlock type="fixed" width="400px">
          <CarRoutes data={carRoutesData} />
        </SplitBlock>
      </Split>
    </div>
  );
};

export default Preview;