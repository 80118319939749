import React, { FC } from "react";

import "./split.scss";

interface splitProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children?: React.ReactNode
}

interface splitBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children?: React.ReactNode
  type: "grow" | "fixed"
  width?: string
}

const Split: FC<splitProps> = ({children, className}) => {
  return (
    <div className={["split", className].join(" ")}>{children}</div>
  );
};

export const SplitBlock: FC<splitBlockProps> = ({children, type, width}) => {
  return (
    <div 
      style={{width: width ? width : "auto"}} 
      className={"split__block" + (type === "grow" ? " split--grow" : " split--fixed")}
    >
      {children}
    </div>
  );
}

export default Split;