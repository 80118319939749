import { carRoute } from "../types/carRoute"

export const carRoutes: carRoute[] = [
  {
    from: {
      name: "Минеральные воды",
      isAirport: true,
    },
    to: {
      name: "Кисловодск",
      isAirport: false,
    },
    time: "48 мин",
    distance: "52 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: true,
    },
    to: {
      name: "Пятигорск",
      isAirport: false,
    },
    time: "38 мин",
    distance: "31 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: true,
    },
    to: {
      name: "Ессентуки",
      isAirport: false,
    },
    time: "32 мин",
    distance: "37 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: false,
    },
    to: {
      name: "Краснодар",
      isAirport: false,
    },
    time: "5 часов 15 мин",
    distance: "400 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: false,
    },
    to: {
      name: "Домбай",
      isAirport: false,
    },
    time: "3 часа 48мин",
    distance: "220 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: false,
    },
    to: {
      name: "Эльбрус",
      isAirport: false,
    },
    time: "3 часа",
    distance: "187 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: false,
    },
    to: {
      name: "Азау",
      isAirport: false,
    },
    time: "3 часа",
    distance: "187 км",
  },
  {
    from: {
      name: "Минеральные воды",
      isAirport: false,
    },
    to: {
      name: "Чегет",
      isAirport: false,
    },
    time: "3 часа 5 мин",
    distance: "189 км",
  }
]