import { carAbout } from "../../types/carAbout";

import { ReactComponent as Circle } from "../../../svg/circle.svg"; 

export const premiumCars: carAbout[] = [
  {
    name: "Mercedes-Benz S-class W223",
    description: "Роскошный седан. Он отличается просторным салоном, расширенными функциями безопасности и мощным двигателем",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Burmester"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      },
      {
        icon: <Circle/>,
        value: "Панорамная крыша"
      }
    ],
    images: [
      "/common/cars/premium/one/1.png",
      "/common/cars/premium/one/2.png",
      "/common/cars/premium/one/3.png",
      "/common/cars/premium/one/4.png",
      "/common/cars/premium/one/5.png",
      "/common/cars/premium/one/6.png",
    ]
  },
  {
    name: "Mercedes-Benz S-class W223",
    description: "Роскошный седан. Он отличается просторным салоном, расширенными функциями безопасности и мощным двигателем",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Burmester"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      },
      {
        icon: <Circle/>,
        value: "Панорамная крыша"
      }
    ],
    images: [
      "/common/cars/premium/two/1.png",
      "/common/cars/premium/two/2.png",
      "/common/cars/premium/two/3.png",
      "/common/cars/premium/two/4.png",
      "/common/cars/premium/two/5.png",
      "/common/cars/premium/two/6.png",
    ]
  },
  {
    name: "BMW 7 Long",
    description: "Роскошный полноразмерный седан, сочетающий в себе элегантность и передовые технологии",
    specs: [
      {
        icon: <Circle/>,
        value: "Акустика Bowers & Wilkins"
      },
      {
        icon: <Circle/>,
        value: "Раздельный климат контроль"
      },
      {
        icon: <Circle/>,
        value: "Панорамная крыша"
      }
    ],
    images: [
      "/common/cars/premium/three/1.png",
      "/common/cars/premium/three/2.png",
      "/common/cars/premium/three/3.png",
      "/common/cars/premium/three/4.png",
      "/common/cars/premium/three/5.png",
      "/common/cars/premium/three/6.png"
    ]
  }
]