export const answers: { value: string; text: string }[] = [
  {
    value: "Как оставить заявку на трансфер?",
    text: `Вы можете воспользоваться специальной формой для заказа. Заполните форму и мы свяжемся с вами в течении 20 минут. Также вы можете заказать трансфер по телефону ${process.env.REACT_APP_PRETTY_PHONE}`
  },
  {
    value: "Цена зависит от даты поездки?",
    text: "Нет. Вы можете заказать трансфер на любую дату, но не менее чем за 24 часа до поездки."
  },
  {
    value: "Цена за машину или за человека?",
    text: "За машину. Цена не зависит от количества пассажиров. У каждого класса указано, сколько пассажиров и мест стандартного багажа вмещает автомобиль. Детские кресла по запросу без доплаты."
  },
  {
    value: "Что если я еду с детьми?",
    text: "Дети считаются пассажирами. При оформлении заказа с детьми, кресла беспплатно. Важно только указатть количество детей"
  },
  {
    value: "Как заказать трансфер в аэропорт?",
    text: "В поле «Откуда» укажите город, откуда вы едете. В поле «Куда» выберите ваш аэропорт. Он будет помечен знаком самолёта"
  }
]