export const routesLenght: {[key: string]: {distance: number, time: string}} = {
  "кисловодск мин-воды минеральные воды": {
    distance: 52,
    time: "48 мин",
  },
  "пятигорск мин-воды минеральные воды": {
    distance: 31,
    time: "38мин",
  },
  "ессентуки мин-воды минеральные воды": {
    distance: 37,
    time: "32мин",
  },
  "краснодар мин-воды минеральные воды": {
    distance: 400,
    time: "5ч. 15м.",
  },
  "домбай мин-воды минеральные воды": {
    distance: 220,
    time: "3ч. 48м.",
  },
  "эльбрус мин-воды минеральные воды": {
    distance: 187,
    time: "3ч.",
  },
  "азау мин-воды минеральные воды": {
    distance: 187,
    time: "3ч.",
  },
  "чегет мин-воды минеральные воды": {
    distance: 189,
    time: "3ч. 5м.",
  },
}