import React, { FC } from "react"
import { Helmet } from "react-helmet";

import { usePage } from "./../../hooks/usePage";

import Split, { SplitBlock } from "./../../components/common/Split/Split";
import Preview from "../../components/Preview/Preview";
import OrderPreview from "../../components/OrderPreview/OrderPreview";
import Counters from "../../components/Counters/Counters";
import CarCards from "../../components/CarCards/CarCards";
import Answers from "../../components/Answers/Answers";

import { ReactComponent as Car } from "../../svg/car.svg";
import { ReactComponent as Happy } from "../../svg/happy.svg";
import { ReactComponent as Call } from "../../svg/call.svg";

const IndexPage: FC = () => {
  usePage();

  return (
    <section className="content">
      <Helmet>
        <title>Закажите минивены и машины премиум класса в Минеральных Водах и КМВ на любое расстояние vip-transfer-kmv.ru</title>
      </Helmet>
      <div className="block-container">
        <Preview />
      </div>
      <div className="block-container">
        <div className="title">
          <h2>Как работает трансфер</h2>
        </div>
        <Counters data={[
          {
            type: "icon",
            title: <Call />,
            subtitle: "Отправьте нам заявку или позвоните по телефону. Обсудите с нами условия и детали перевозки",
          },
          {
            type: "icon",
            title: <Car />,
            subtitle: "В обозначенное время встретим вас в указанном месте",
          },
          {
            type: "icon",
            title: <Happy />,
            subtitle: "Наслаждайтесь поездкой на наших автомобилях",
          }
        ]}/>
      </div>
      <div className="block-container">
        <div className="title">
          <h2>Наши автомобили</h2>
        </div>
        <CarCards data={[
          {
            title: "Минивены",
            subtitle: "Вип минивены для большой компании",
            img: "/common/car-van.png",
            cars: 18,
            persons: "5 - 7",
            link: "/van"
          },
          {
            title: "Премиум",
            subtitle: "Премиум машины для вашего удобства",
            img: "/common/car-premium.png",
            cars: 15,
            persons: "4",
            link: "/premium"
          },
          {
            title: "Бизнес",
            subtitle: "Машины на любой случай",
            img: "/common/car-business.png",
            cars: 20,
            persons: "4",
            link: "/business"
          }
        ]}/>
      </div>
      <div className="block-container">
        <div className="constructor">
          <h3>Ваш комфорт - наша забота</h3>
          <Counters data={[
            {
              type: "text",
              title: "1",
              subtitle: "Трансфер в любую точку России, авто оснащены всем необходимым",
            },
            {
              type: "text",
              title: "2",
              subtitle: "Предлагаем услуги для частных лиц и бизнес клиентов",
            },
            {
              type: "text",
              title: "3",
              subtitle: "Трансфер для деловых встреч, мероприятий и конференций",
            }
          ]}/>
          <OrderPreview />
        </div>
      </div>
      <div className="block-container">
        <Split>
          <SplitBlock type="grow">
            <h3>Часто задаваемые вопросы</h3>
            <Answers />
          </SplitBlock>
          <SplitBlock type="grow">
            <h3>Помощь</h3>
            <span style={{fontSize: "90%", margin: "10px 0px"}}>Служба поддержки работает круглосуточно</span>
            <Split className="help">
              <SplitBlock type="grow">
                <strong>{process.env.REACT_APP_PRETTY_PHONE}</strong>
              </SplitBlock>
              <SplitBlock type="grow">
                <strong>8 (961) 474-77-47</strong>
              </SplitBlock>
            </Split>
          </SplitBlock>
        </Split>
      </div>
    </section>
  );
};

export default IndexPage