import React, { FC } from "react"
import { Outlet } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileMenu from "../../components/MobileMenu/MobileMenu";

const IndexLayout: FC = () => {
  return (
    <>
      <Header />
      <MobileMenu />
      <main className="main">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default IndexLayout;