import React, { FC } from "react";

import "./counters.scss";

interface countersProps {
  data: {
    type: "text" | "icon",
    title: string | React.ReactNode,
    subtitle: string,
  }[]
}

const Counters: FC<countersProps> = ({ data }) => {
  return (
    <div className="counters">
      {
      data.map((item, index) => (
        <div key={index} className="counters__item">
          <div className="counters__item-title">
            {
              item.type === "text" 
              ? <strong>{item.title}</strong>
              : item.title
            }
          </div>
          <span>{item.subtitle}</span>
        </div>
      ))
      }
    </div>
  );
};

export default Counters;