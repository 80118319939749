import React, { FC } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Car } from "../../svg/car.svg";
import { ReactComponent as Home } from "../../svg/home.svg";
import { ReactComponent as Van } from "../../svg/van.svg";
import { ReactComponent as Diamond } from "../../svg/diamond.svg";
import { ReactComponent as Recomended } from "../../svg/recomended.svg";

import "./mobilemenu.scss";

const MobileMenu: FC = () => {
  return (
    <nav className="mobile-menu">
      <div className="mobile-menu__link">
        <Home />
        <Link to="/">Главная</Link>
      </div>
      <div className="mobile-menu__link">
        <Car />
        <Link to="/order">Маршрут</Link>
      </div>
      <div className="mobile-menu__link">
        <Van />
        <Link to="/van">Минивены</Link>
      </div>
      <div className="mobile-menu__link">
        <Diamond />
        <Link to="/premium">Премиум</Link>
      </div>
      <div className="mobile-menu__link">
        <Recomended />
        <Link to="/business">Бизнес</Link>
      </div>
    </nav>
  );
};

export default MobileMenu;