import React, { FC } from "react";
import { ReactNotifications } from "react-notifications-component";
import { YMInitializer } from "react-yandex-metrika";

import "./styles/normalize.scss";
import "./styles/fonts.scss";
import "./styles/scrollbar.scss";
import "./styles/colors.scss";
import "./styles/theming.scss";
import "./styles/style.scss";

import "react-notifications-component/dist/theme.css";

import AppRouter from "./router/AppRouter";

const App: FC = () => {
  return (
    <>
      <YMInitializer accounts={[parseInt(process.env.REACT_APP_METRIKA_ID!)]} />
      <ReactNotifications />
      <AppRouter />
    </>
  );
};

export default App;