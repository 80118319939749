import React, { FC, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import InputList from "../common/InputList/InputList";

import { ReactComponent as Person } from "../../svg/person.svg";

import { cities } from "../../lib/data/cities";

import "./orderpreview.scss";

interface orderProps {
  from: string
  to: string
  persons: number
  date: string
}

function reducer(state: Partial<orderProps>, action: Record<string, string | number>) {
  return {...state, ...action};
}

interface orderPreviewProps {
  data?: Partial<orderProps>
}

const OrderPreview: FC<orderPreviewProps> = ({ data }) => {
  const router = useNavigate();

  const [state, dispatch] = useReducer(reducer, {
    from: "",
    to: "",
    persons: 1,
    date: new Date().toISOString().slice(0, 10),
  });

  useEffect(() => {
    dispatch(data || {});
  }, [data])

  return (
    <div className="order-preview">
      <div className="order-preview__inputs">
        <div className="order-preview__input"> 
          <InputList defaultValue={state.from} onChange={(value) => dispatch({from: value})} placeholder="Откуда" data={cities} />
        </div>
        <div className="order-preview__input"> 
          <InputList defaultValue={state.to} onChange={(value) => dispatch({to: value})} placeholder="Куда" data={cities} />
        </div>
        <div className="order-preview__input order-preview__date"> 
          <input onChange={(e) => dispatch({date: e.target.value})} type="date" defaultValue={state.date} min={state.date} />
        </div>
        <div className="order-preview__input order-preview__persons">
          <input onChange={(e) => dispatch({persons: e.target.value})} defaultValue={1} type="number" min="1" max="50" />
          <Person />
        </div>
      </div>
      <button onClick={() => router(`/order?from=${state.from}&to=${state.to}&persons=${state.persons}&date=${state.date}`)} className="order-preview__submit">Забронировать</button>
    </div>
  );
};

export default OrderPreview;