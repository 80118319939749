import React, { FC } from "react";
import { Helmet } from "react-helmet";

import { usePage } from "../../hooks/usePage";

import CarAbout from "../../components/CarAbout/CarAbout";
import Counters from "../../components/Counters/Counters";
import OrderPreview from "../../components/OrderPreview/OrderPreview";
import Split, { SplitBlock } from "../../components/common/Split/Split";
import Answers from "../../components/Answers/Answers";

import { vanCars } from "../../lib/data/cars/vanCars";

const VanPage: FC = () => {
  usePage();

  return (
    <section className="content">
      <Helmet>
        <title>Закажите минивены в Минеральных Водах и КМВ на любое расстояние vip-transfer-kmv.ru</title>
      </Helmet>
      <div className="block-container">
        <div className="title">
          <h3 style={{textAlign: "center"}}>Закажите минивены в Минеральных Водах и КМВ на любое расстояние</h3>
          <span style={{textAlign: "center"}}>Ощутите незабываемые путешествия с Vip Transfer KMV!</span>
        </div>
      </div>
      {
        vanCars.map((item, index) => (
          <div key={index} className="block-container">
            <CarAbout data={item} />
          </div>
        ))
      }
      <div className="block-container">
        <div className="constructor">
          <h3>Ваш комфорт - наша забота</h3>
          <Counters data={[
            {
              type: "text",
              title: "1",
              subtitle: "Трансфер в любую точку России, авто оснащены всем необходимым",
            },
            {
              type: "text",
              title: "2",
              subtitle: "Предлагаем услуги для частных лиц и бизнес клиентов",
            },
            {
              type: "text",
              title: "3",
              subtitle: "Трансфер для деловых встреч, мероприятий и конференций",
            }
          ]}/>
          <OrderPreview />
        </div>
      </div>
      <div className="block-container">
        <Split>
          <SplitBlock type="grow">
            <h3>Часто задаваемые вопросы</h3>
            <Answers />
          </SplitBlock>
          <SplitBlock type="grow">
            <h3>Помощь</h3>
            <span style={{fontSize: "90%", margin: "10px 0px"}}>Служба поддержки работает круглосуточно</span>
            <Split className="help">
              <SplitBlock type="grow">
                <strong>{process.env.REACT_APP_PRETTY_PHONE}</strong>
              </SplitBlock>
              <SplitBlock type="grow">
                <strong>8 (961) 474-77-47</strong>
              </SplitBlock>
            </Split>
          </SplitBlock>
        </Split>
      </div>
    </section>
  );
};

export default VanPage;