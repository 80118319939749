import React, { FC } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../svg/logo.svg";
import { ReactComponent as TeleramIcon } from "../../svg/social/telegram.svg";
import { ReactComponent as WhatsappIcon } from "../../svg/social/whatsapp.svg";
 
import "./footer.scss";

const Footer: FC = () => {
  return (
    <footer className="footer">
      <div className="footer__content container">
        <div className="footer__logo">
          <Logo />
          <div className="footer__logo-text">
            <strong>Vip Transfer KMV</strong>
            <div className="footer__contact">
              <a href={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PRETTY_PHONE}</a>
            </div>
            <div className="footer__social">
              <a href={process.env.REACT_APP_WHATSAPP}>
                <WhatsappIcon />
              </a>
              <a href={process.env.REACT_APP_TELEGRAM}>
                <TeleramIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__links">
          <nav className="footer__nav">
            <Link to="/">Главная</Link>
            <Link to="/van">Минивены</Link>
            <Link to="/premium">Премиум</Link>
            <Link to="/business">Бизнес</Link>
            <Link to="/order">Маршрут</Link>
            <Link to="/legal">Оферта</Link>
          </nav>
          <div className="footer__copyright">
            <span>Все права защищены © {process.env.REACT_APP_URL} 2024</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;