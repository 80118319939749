import React, { FC } from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

import "./ymap.scss";

interface mapProps {
  placemarks: {
    geo: [number, number],
    caption: string
  }[],
  state: any,
  style?: any
}

const YMap: FC<mapProps> = ({ placemarks = [], state, style }) => {
  return (
    <YMaps query={{ apikey: process.env.REACT_APP_MAP_API }}>
      <Map className="map" state={state} style={style}>
        {
          placemarks.map((item, index) => (
            <Placemark key={index} properties={{iconCaption: item.caption}} geometry={item.geo} />
          ))
        }
      </Map>
    </YMaps>
  );
};

export default YMap;