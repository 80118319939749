import React, { FC, useState } from "react";

import { ReactComponent as SmallArrow } from "../../../svg/smallarrow.svg";
 
import "./carousel.scss";

interface carouselProps {
  data: string[],
  height: string
}

const Carousel: FC<carouselProps> = ({ data, height }) => {
  const [current, setCurrent] = useState<string>(data[0] || "");

  function stepElement(direction: number) {
    const index = data.indexOf(current);

    if (index + direction < 0) {
      setCurrent(data[data.length - 1]);
    } else if (index + direction > data.length - 1) {
      setCurrent(data[0]);
    } else {
      setCurrent(data[index + direction]);
    }
  }

  return (
    <div style={{height}} className="carousel">
      <div className="carousel__image">
        <img src={current} alt="" />
      </div>
      <div className="carousel__btns">
        <button onClick={() => stepElement(-1)}>
          <SmallArrow style={{rotate: "90deg"}} />
        </button>
        <button onClick={() => stepElement(1)}>
          <SmallArrow style={{rotate: "270deg"}} />
        </button>
      </div>
    </div>
  );
};

export default Carousel;