export const cities: string[] = [
  "Минеральные воды",
  "Аэропорт Мин-Воды",
  "Пятигорск",
  "Ессентуки",
  "Кисловодск",
  "Ставрополь",
  "Домбай",
  "Краснодар",
  "Эльбрус",
  "Азау",
  "Чегет",
]