import React, { FC, useState, useEffect, useRef } from "react";

import { useClickOver } from "../../../hooks/useClickOver";

import { ReactComponent as Close } from "../../../svg/close.svg";

import "./inputlist.scss";

interface InputListProps {
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  clearValue?: boolean;
  pickOnly?: boolean;
  onChange?: (value: string) => void;
  data?: string[];
}

const InputList: FC<InputListProps> = ({
  placeholder,
  pickOnly = false,
  clearValue = true,
  defaultValue,
  onChange,
  data,
  className
}) => {

  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState<string>("");
  const [dataOpen, setDataOpen] = useState<boolean>(false);
  const [formatData, setFormatData] = useState<string[]>(data || []);

  useClickOver(inputRef, () => {
    setDataOpen(false);
  })

  function dataFormat(value: string, data: string[]) {
    setFormatData(data.filter(item => item.toLowerCase().includes(value.toLowerCase())));
  }

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    !pickOnly && dataFormat(inputValue, data || []);
    onChange && onChange(inputValue);
  }, [inputValue]); // eslint-disable-line

  return (
    <div ref={inputRef} className={["input-list", className, dataOpen && data && formatData.length !== 0 ? "input-list--active" : ""].join(" ")} onClick={() => setDataOpen(!dataOpen)}>
      <div className="input-list__input">
        <input type="text" readOnly={pickOnly} placeholder={placeholder} onChange={(e) => setInputValue(e.target.value)} value={inputValue}/>
        {
          clearValue &&
          <button style={{opacity: inputValue ? 1 : 0}} onClick={() => setInputValue("")} className="input-list__clear">
            <Close />
          </button>
        }
      </div>
      <div className="input-list__data">
        {
          formatData.map((item, index) => 
            <button onClick={() => setInputValue(item)} key={index}>{item}</button>
          )
        }
      </div>
    </div>
  );
};

export default InputList;